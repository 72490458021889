<template>
  <div class="cts_box">
    <h2 class="sub_ttl"><span>マイQRコード</span></h2>
    <h3 class="min_ttl">マイQRコードをスキャンしてもらってフォローしてもらいましょう。</h3>
    <div class="center">
      <VueQrcode :value="myTimelineUrl()" :options="{ width: 300, margin: 2 }"  style="border: 4px solid #000;" />
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import VueQrcode from "@chenfengyuan/vue-qrcode"

export default {
  mixins: [Libraries],
  components: {
    VueQrcode
  }
}
</script>